"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
require("@xyflow/react/dist/style.css");
var react_1 = require("@xyflow/react");
var challenge_1 = require("../../../../reducers/slices/challenge/challenge");
var constants_1 = require("./components/constants");
var react_redux_1 = require("react-redux");
var react_2 = require("@nextui-org/react");
var challenge_node_menu_1 = require("./components/challenge_node_menu");
var lets_trade_utils_1 = require("lets-trade-utils");
var react_3 = require("@iconify/react");
var label_1 = require("../../../../controls/label");
var getNodeConfig = function (nodeType, category) {
    return constants_1.NodeConfigurations[category].find(function (node) { return node.type === nodeType; });
};
function ChallengeEditor(_a) {
    var className = _a.className;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.challengeState; }), nodes = _b.nodes, edges = _b.edges, nodeModal = _b.nodeModal;
    var _onNodesChange = function (nodeChanges) { return dispatch((0, challenge_1.onNodesChange)(nodeChanges)); };
    var _onEdgesChange = function (edgeChanges) { return dispatch((0, challenge_1.onEdgesChange)(edgeChanges)); };
    var _onConnect = function (connection) { return dispatch((0, challenge_1.onConnect)(connection)); };
    var addNode = function (_a) {
        var nodeType = _a.nodeType, category = _a.category;
        var id = "".concat(nodeType, "-").concat(lets_trade_utils_1.Generate.randomAlphanumeric(8));
        var nodeConfig = getNodeConfig(nodeType, category);
        dispatch((0, challenge_1.setNodes)(__spreadArray(__spreadArray([], nodes, true), [
            {
                id: id,
                type: nodeType,
                position: { x: 0, y: 0 },
                data: nodeConfig.data,
                selected: true,
            },
        ], false)));
        if (nodeModal.nodeId) {
            dispatch((0, challenge_1.setEdges)(__spreadArray(__spreadArray([], edges, true), [{ id: id, source: nodeModal.nodeId, target: id }], false)));
        }
        dispatch((0, challenge_1.setIsEdited)(true));
    };
    return ((0, jsx_runtime_1.jsxs)(react_1.ReactFlow, __assign({ nodes: nodes, edges: edges, onNodesChange: _onNodesChange, onEdgesChange: _onEdgesChange, onConnect: _onConnect, nodeTypes: constants_1.nodeTypes, fitView: true, fitViewOptions: {
            maxZoom: 1,
        }, className: "grow ".concat(className, " shadow-inner"), nodesDraggable: false, panOnDrag: [2, 1], zoomOnScroll: false, zoomOnPinch: true, zoomOnDoubleClick: false, panOnScroll: true, panOnScrollSpeed: 0.5, nodesConnectable: false }, { children: [(0, jsx_runtime_1.jsx)(react_1.Background, {}), (0, jsx_runtime_1.jsx)(react_1.Panel, __assign({ position: "top-left" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col items-center gap-4" }, { children: [(0, jsx_runtime_1.jsx)(label_1.Label, { label: "Objectives", textColor: "text-primary", size: "large", bold: true }), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ size: "sm", startContent: (0, jsx_runtime_1.jsx)(react_3.Icon, { icon: "mdi:add" }), color: (nodes === null || nodes === void 0 ? void 0 : nodes.length) ? "primary" : "secondary", onClick: function () { return dispatch((0, challenge_1.setNodeModal)({ isOpen: true, nodeTypes: ["objective"] })); }, radius: "md" }, { children: "Add Objective" }))] })) })), nodeModal.isOpen && ((0, jsx_runtime_1.jsx)(challenge_node_menu_1.ChallengeNodeMenu, { onNodeSelect: function (props) {
                    addNode(props);
                    dispatch((0, challenge_1.setNodeModal)(__assign(__assign({}, nodeModal), { isOpen: false })));
                } }))] })));
}
exports.default = ChallengeEditor;
